<template>
  <div>
    <CRow class="m-4">
      <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="$router.push('/alarm')">{{$t('cloud.title.alarm')}}</el-button>
    </CRow>
    <CRow class="justify-content-center">
      <CCol lg="12" xl="10" xxl="8">
        <CCard>
          <CCardHeader>
            <CRow>
              <div class="mx-3">
                <strong style="line-height:36px;">{{$t('cloud.title.alarm')}}</strong>
              </div>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div v-if="snapshots.length">
              <div style="position:relative;">
                <CImg v-if="snapshots.length" class="w-100 my-1 rounded" :src="snapshots[img_id].signed_url" />
                <CImg v-else class="w-100 my-1 rounded" :src="snapshot_bak" />
                <CButton class="floating m-2 text-light" variant="outline" color="dark" @click="onViewFullscreen">
                  <CIcon name="fullscreen" size="xl" />
                </CButton>
              </div>
              <CRow class="justify-content-center flex-wrap">
                <CButton class="p-0 m-0" v-for="(snapshot, index) in snapshots" :key="`img-thumb-${index}`" @click="onThumb(index)">
                  <CImg
                    :class="`thumbnail ${index===img_id?'bg-danger':'bg-white'}`"
                    :src="snapshot.signed_url"
                  />
                </CButton>
              </CRow>
              <el-divider />
            </div>
            <CCard v-if="alarm">
              <CCardBody>
                <CRow>
                  <div class="mx-4 my-3">
                    <CIcon class="mx-4 text-dark" width="48px" height="48px" :name="$resource.EVENT_ICON_NAMES[alarm.event_type]" />
                  </div>
                  <div>
                    <li>
                      <span class="font-weight-bold">{{$t('cloud.title.event_type')}}:&nbsp;&nbsp;</span><span>{{$t(`cloud.title.eventtype_${alarm.event_type}`)}}</span>
                    </li>
                    <li>
                      <span class="font-weight-bold">{{$t('cloud.title.event_name')}}:&nbsp;&nbsp;</span><span>{{alarm.event_name}}</span><span v-if="alarm.src_event_name"> [{{alarm.src_event_name}}]</span>
                    </li>
                    <li>
                      <span class="font-weight-bold">{{$t('cloud.title.device_name')}}:&nbsp;&nbsp;</span><span>{{alarm.bridge_name}}{{alarm.ch ? ` [CH${alarm.ch}]`:''}}</span>
                    </li>
                    <li>
                      <span class="font-weight-bold">{{$t('cloud.title.action')}}:&nbsp;&nbsp;</span><span>{{actionList}}</span>
                    </li>
                    <li>
                      <span class="font-weight-bold">{{$t('cloud.title.event_time')}}:&nbsp;&nbsp;</span><span>{{alarm.fmt_time}}</span>
                    </li>
                    <li v-if="alarm.event_meta1">
                      <span class="font-weight-bold">{{$t('cloud.title.event_meta')}}:&nbsp;&nbsp;</span><span>{{alarm.event_meta1}}</span>
                    </li>
                    <li v-if="alarm.event_meta2">
                      <span class="font-weight-bold">{{$t('cloud.title.event_meta')}}:&nbsp;&nbsp;</span><span>{{alarm.event_meta2}}</span>
                    </li>
                    <li v-if="alarm.event_meta3">
                      <span class="font-weight-bold">{{$t('cloud.title.event_meta')}}:&nbsp;&nbsp;</span><span>{{alarm.event_meta3}}</span>
                    </li>
                  </div>
                </CRow>
                <CRow class="justify-content-end">
                  <CButton @click="onToggle('bookmark')" :color="alarm.bookmark?'info':'light'">{{$t('cloud.title.bookmark')}}</CButton>
                  <CButton class="mx-1" @click="onToggle('useless')" :color="alarm.useless?'info':'light'">{{$t('cloud.title.ignore')}}</CButton>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from "vuex"

export default {
  name: 'AlarmView',

  data() {
    return {
      show: false,
      loading: false,
      title: this.$t('cloud.title.alarm'),
      alarm: null,
      snapshots: [],
      img_id: null,
      snapshot_bak: '/img/logo_notfound.png'
    }
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    getTitle() {
      if (!this.alarm) return this.$t('cloud.title.alarm');
      const time_fmt = this.$utility.GetDateTimeStr("$yyyy-$mm-$dd $HH:$MM:$ss", new Date(this.alarm.ts));
      return `${this.$t('cloud.title.alarm')} - ${this.alarm.event_name} (${time_fmt})`;
    },
    actionList() {
      const rule = this.alarm.metadata.rule || this.alarm.metadata;
      if (!rule.actions || rule.actions.length <= 0) return '-';
      const type_list = rule.actions.map(el => this.$t(`cloud.title.actiontype_${el.type}`));
      return type_list.join(', ');
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let alarm_key = this.$route.query.short_key;
      axios.get(`/api/alarm/deeplink/${alarm_key}/`)
        .then(response => {
          this.alarm = response.data.alarm;
          this.snapshots = response.data.snapshots;
          this.img_id = this.snapshots.length - 1;
        });
    },
    onThumb(index) {
      this.img_id = index;
    },
    onViewFullscreen() {
      window.open(this.snapshots[this.img_id].signed_url, "_blank");
    },
    onToggle(tag) {
      let req = null;
      const params = {
        bookmark: this.alarm.bookmark,
        useless: this.alarm.useless
      };
      if (tag === 'bookmark') {
        params.bookmark = !params.bookmark;
        if (params.bookmark && params.useless) {
          params.useless = false;
        }
      } else if (tag === 'useless') {
        params.useless = !params.useless;
        if (params.useless && params.bookmark) {
          params.bookmark = false;
        }
      }

      this.loading = true;
      if (tag === 'bookmark') {
        req = axios.put(`/api/alarm/${this.alarm.id}/tag/`, params)
      } else if (tag === 'useless') {
        req = axios.put(`/api/alarm/${this.alarm.id}/tag/`, params)
      } else {
        return;
      }
      req.then(response => {
        this.alarm = this.$utility.copy(response.data);
        this.alarm.fmt_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.alarm.ts));
        this.alarm.device_name = `${this.alarm.bridge_name} (${this.alarm.bridge_mac}) - CH ${this.alarm.ch}`;
        this.alarm.event_type_name = this.$t(`cloud.title.eventtype_${this.alarm.event_type}`);
        this.$emit('tag', this.alarm);
      }).catch(error => {
        console.error(error);
        this.$notify.error({
          title: this.$t('cloud.title.request_fail_title'),
          message: this.$t('cloud.title.request_fail_content'),
          offset: 30
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  cursor: pointer;
  width:96px;
  height:54px;
  padding:2px;
  border-radius: .3rem;
}
.floating {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>